export const CONSONANT_LIST: string = "B-DF-HJ-NP-TV-Z";
export const VOWEL_LIST: string = "AEIOU";
export const OMOCODE_NUMBER_LIST: string = "\\dLMNP-V";
export const OMOCODE_NON_ZERO_NUMBER_LIST: string = "1-9MNP-V";
export const OMOCODE_ZERO_LIST: string = "0L";
export const MONTH_LIST: string = "A-EHLMPR-T";
export const MONTH_30DAYS_LIST: string = "DHPS";
export const MONTH_31DAYS_LIST: string = "ACELMRT";
export const CITY_CODE_LIST: string = "A-M";
export const COUNTRY_CODE_LIST: string = "Z";

export const CF_NAME_MATCHER: string = `[A-Z][${VOWEL_LIST}][${VOWEL_LIST}X]|[${CONSONANT_LIST}]{2}[A-Z]`;
export const CF_SURNAME_MATCHER: string = CF_NAME_MATCHER;
export const CF_FULL_NAME_MATCHER: string = `(?:${CF_NAME_MATCHER}){2}`;

export const YEAR_MATCHER: string = `[${OMOCODE_NUMBER_LIST}]{2}`;
export const LEAP_YEAR_MATCHER: string = "[02468LNQSU][048LQU]|[13579MPRTV][26NS]";
export const MONTH_MATCHER: string = `[${MONTH_LIST}]`;
export const DAY_2X_MATCHER: string = "[26NS]";
export const DAY_3X_MATCHER: string = "[37PT]";
export const DAY_29_MATCHER: string = `[${OMOCODE_ZERO_LIST}4Q][${OMOCODE_NON_ZERO_NUMBER_LIST}]|[1256MNRS][${OMOCODE_NUMBER_LIST}]`;
export const DAY_30_MATCHER: string = `[${DAY_3X_MATCHER}][${OMOCODE_ZERO_LIST}]`;
export const DAY_31_MATCHER: string = `[${DAY_3X_MATCHER}][${OMOCODE_ZERO_LIST}1M]`;

export const DAY_MATCHER: string = `(?:${DAY_29_MATCHER}|${DAY_3X_MATCHER}[${OMOCODE_ZERO_LIST}1M])`;
export const MALE_DAY_MATCHER: string = `(?:[${OMOCODE_ZERO_LIST}][${OMOCODE_NON_ZERO_NUMBER_LIST}]|[12MN][${OMOCODE_NUMBER_LIST}]|[3P][${OMOCODE_ZERO_LIST}1M])`;
export const FEMALE_DAY_MATCHER: string = `(?:[4Q][${OMOCODE_NON_ZERO_NUMBER_LIST}]|[56RS][${OMOCODE_NUMBER_LIST}]|[7T][${OMOCODE_ZERO_LIST}1M])`;
export const MONTH_DAY_MATCHER: string = `${MONTH_MATCHER}(?:${DAY_29_MATCHER})|[${MONTH_30DAYS_LIST}]${DAY_30_MATCHER}|[${MONTH_31DAYS_LIST}]${DAY_31_MATCHER}`;
export const FULL_DATE_MATCHER: string = `${YEAR_MATCHER}(?:${MONTH_MATCHER}(?:[${OMOCODE_ZERO_LIST}4Q][${OMOCODE_NON_ZERO_NUMBER_LIST}]|[15MR][${OMOCODE_NUMBER_LIST}]|${DAY_2X_MATCHER}[0-8LMNP-U])|[${MONTH_30DAYS_LIST}]${DAY_3X_MATCHER}[${OMOCODE_ZERO_LIST}]|[${MONTH_31DAYS_LIST}]${DAY_3X_MATCHER}[${OMOCODE_ZERO_LIST}1M]|[${MONTH_30DAYS_LIST}${MONTH_31DAYS_LIST}]${DAY_2X_MATCHER}[9V])|(?:${LEAP_YEAR_MATCHER})B${DAY_2X_MATCHER}[9V]`;
export const MALE_FULL_DATE_MATCHER: string = `${YEAR_MATCHER}(?:${MONTH_MATCHER}(?:[${OMOCODE_ZERO_LIST}][${OMOCODE_NON_ZERO_NUMBER_LIST}]|[1M][${OMOCODE_NUMBER_LIST}]|[2N][0-8LMNP-U])|[${MONTH_30DAYS_LIST}][3P][${OMOCODE_ZERO_LIST}]|[${MONTH_31DAYS_LIST}][3P][${OMOCODE_ZERO_LIST}1M]|[${MONTH_30DAYS_LIST}${MONTH_31DAYS_LIST}][2N][9V])|(?:${LEAP_YEAR_MATCHER})B[2N][9V]`;
export const FEMALE_FULL_DATE_MATCHER: string = `${YEAR_MATCHER}(?:${MONTH_MATCHER}(?:[4Q][${OMOCODE_NON_ZERO_NUMBER_LIST}]|[5R][${OMOCODE_NUMBER_LIST}]|[6S][0-8LMNP-U])|[${MONTH_30DAYS_LIST}][7T][${OMOCODE_ZERO_LIST}]|[${MONTH_31DAYS_LIST}][7T][${OMOCODE_ZERO_LIST}1M]|[${MONTH_30DAYS_LIST}${MONTH_31DAYS_LIST}][6S][9V])|(?:${LEAP_YEAR_MATCHER})B[6S][9V]`;

export const CITY_CODE_MATCHER: string = `[${CITY_CODE_LIST}](?:[${OMOCODE_NON_ZERO_NUMBER_LIST}][${OMOCODE_NUMBER_LIST}]{2}|[${OMOCODE_ZERO_LIST}](?:[${OMOCODE_NON_ZERO_NUMBER_LIST}][${OMOCODE_NUMBER_LIST}]|[${OMOCODE_ZERO_LIST}][${OMOCODE_NON_ZERO_NUMBER_LIST}]))`;
export const COUNTRY_CODE_MATCHER: string = `${COUNTRY_CODE_LIST}[${OMOCODE_NON_ZERO_NUMBER_LIST}][${OMOCODE_NUMBER_LIST}]{2}`;
export const BELFIORE_CODE_MATCHER: string = `(?:[${CITY_CODE_LIST}${COUNTRY_CODE_LIST}][${OMOCODE_NON_ZERO_NUMBER_LIST}][${OMOCODE_NUMBER_LIST}]{2})|(?:[${CITY_CODE_LIST}][${OMOCODE_ZERO_LIST}](?:[${OMOCODE_NON_ZERO_NUMBER_LIST}][${OMOCODE_NUMBER_LIST}]|[${OMOCODE_ZERO_LIST}][${OMOCODE_NON_ZERO_NUMBER_LIST}]))`;

export const CHECK_DIGIT: string = "[A-Z]";

export const CODICE_FISCALE: string = `${CF_FULL_NAME_MATCHER}(?:${FULL_DATE_MATCHER})(?:${BELFIORE_CODE_MATCHER})${CHECK_DIGIT}`;

export const PARTIAL_CF_NAME_MATCHER: string = `[A-Z][${VOWEL_LIST}]?|[${CONSONANT_LIST}]{1,2}`;
export const PARTIAL_CF_FULL_NAME: string = `(?:${PARTIAL_CF_NAME_MATCHER})|(?:(?:${CF_NAME_MATCHER})(?:${PARTIAL_CF_NAME_MATCHER})?)`;
export const PARTIAL_YEAR: string = `[${OMOCODE_NUMBER_LIST}]`;
export const PARTIAL_MONTH_DAY: string = `${MONTH_MATCHER}[${OMOCODE_ZERO_LIST}12456MNQRS]?|[${MONTH_30DAYS_LIST}${MONTH_31DAYS_LIST}]${DAY_3X_MATCHER}`;
export const PARTIAL_FULL_DATE: string = `${PARTIAL_YEAR}|(?:${YEAR_MATCHER}(?:${PARTIAL_MONTH_DAY})?)`;
export const PARTIAL_BELFIORE_CODE_MATCHER: string = `[${CITY_CODE_LIST}${COUNTRY_CODE_LIST}](?:[${OMOCODE_NON_ZERO_NUMBER_LIST}][${OMOCODE_NUMBER_LIST}]?)?|[${COUNTRY_CODE_LIST}](?:[${OMOCODE_ZERO_LIST}][${OMOCODE_NUMBER_LIST}]?)?`;

export const PARTIAL_CF: string = `${PARTIAL_CF_FULL_NAME}|(?:${CF_FULL_NAME_MATCHER}(?:(?:${PARTIAL_FULL_DATE})|(?:${FULL_DATE_MATCHER})(?:(?:${PARTIAL_BELFIORE_CODE_MATCHER})|(?:${BELFIORE_CODE_MATCHER})${CHECK_DIGIT}?)?)?)?`;
